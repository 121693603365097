import React from "react"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import SingleProduct from "../components/single-product-page/singleProduct"

import medplusProduct from "../images/product-and-package/mp.jpg"
import medplusImage from "../images/products-without-packaging/medplus.png"

// related product images
import intensiveMoisturiserProduct from "../images/product-and-package/inm.jpg"
import medPlusStickProduct from "../images/product-and-package/mp-stick.jpg"

import bootsLogo from "../images/stockists/boots.png"
import bodycareLogo from "../images/stockists/bodycare.png"
import tescoLogo from "../images/stockists/tesco.png"
import superdrugLogo from "../images/stockists/superdrug.png"
import amazonLogo from "../images/stockists/amazon.png"
import sainsburysLogo from "../images/stockists/sainsburys.png"
//import wilkoLogo from "../images/stockists/wilko.png"
import saversLogo from "../images/stockists/savers.png"

import coldIcon from "../images/icons/product-icon/winter.svg"
import outdoorIcon from "../images/icons/product-icon/outdoors.svg"

const product = {
  image: medplusProduct,
  productImage: medplusImage,
  title: <h1 className="product-overview__heading">MedPlus Jar.</h1>,
  alt: "MedPlus Jar.",
  subTitle: (
    <h2 className="product-overview__sub-heading">
      <br />
      Blistex MedPlus Jar is perfect when lips need some TLC.
      <br />
    </h2>
  ),
  disclaimer: "",
  icons: [
    { title: "outdoors", icon: outdoorIcon },
    { title: "cold weather", icon: coldIcon },
  ],
  overviewCopy: (
    <div>
      <br />
      <p>
        Thanks to conditioning Cocoa Butter and cooling Menthol, it helps to
        restore lips back to a healthy appearance. Even when they’re at their
        most distressed.
      </p>
    </div>
  ),
  listID: "",
  stockists: [
    {
      stockistName: "Boots",
      stockistImage: bootsLogo,
      stockistLink:
        "https://www.boots.com/blistex-medplus-repairing-lip-balm-spf15-7ml-10165250",
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Bodycare",
      stockistImage: bodycareLogo,
      stockistLink:
        "https://www.bodycareonline.co.uk/product/blistex-medplus-7ml/",
      classModifier: "",
      showLink: "",
    },
    {
      stockistName: "Tesco",
      stockistImage: tescoLogo,
      stockistLink: "https://www.tesco.com/groceries/en-GB/products/264866947",
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Sainsbury's",
      stockistImage: sainsburysLogo,
      stockistLink:
        "https://www.sainsburys.co.uk/gol-ui/Product/blistex-med-plus",
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Superdrug",
      stockistImage: superdrugLogo,
      stockistLink:
        "https://www.superdrug.com/Skin/Face-Skin-Care/Lip-Care/Lip-Balms/Blistex-Med-Plus-SPF-15-7ml/p/746633",
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Amazon",
      stockistLink: "https://www.amazon.co.uk/dp/B09HS8WKPR",
      stockistImage: amazonLogo,
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Savers",
      stockistLink:
        "https://www.savers.co.uk/Blistex/Blistex-Med-Plus-SPF15-7ml/p/746633?singleResultSearchPage=true",
      stockistImage: saversLogo,
      classModifier: "",
      showLink: "true",
    },
  ],
  whenToUseCopy: (
    <div>
      <p>
        gently apply the balm evenly all over your lips. re-apply as often as
        you like, especially when lips feel sore or dry, or if you're out and
        about in dry, cold or sunny weather.
      </p>
    </div>
  ),
  ingredients: (
    <p>
      lanolin, hydrogenated coconut oil, cera alba, octyldodecanol, ricinus communis (castor) seed oil, olea europaea (olive) fruit oil, theobroma cacao (Cocoa) seed butter, stearyl alcohol, hydrogenated castor oil, camphor, euphorbia cerifera (candelilla) wax, menthol, vitis vinifera (grape) seed oil, arachidyl alcohol, arachidyl glucoside, behenyl alcohol, benzyl alcohol, beta-carotene, butyrospermum parkii (shea) butter, daucus carota sativa (carrot) root extract, eucalyptus globulus leaf oil, eugenia caryophyllus (clove) flower oil, eugenol, glycine soja (soybean) oil, limonene, menthoxypropanediol, menthyl lactate, parfum, saccharin, tocopherol, zea mays (corn) oil
    </p>
  ),
  faqs: [
    {
      question:
        "Does this product contain any ingredients derived from animals?",
      answer: "Yes, Beeswax and Lanolin",
    },
    {
      question: "Do you sell this product online? ",
      answer:
        "MedPlus Jar is available to buy online through key retailers.  Please click on the where to buy link on each product page.",
    },
    {
      question: "Does this product contain perfume?",
      answer: "No, only flavourings.",
    },
    {
      question: "Does this product contain any parabens?",
      answer: "No.",
    },
  ],
  relatedProducts: [
    {
      productName: "MedPlus Stick.",
      productLink: "/medplus-stick",
      productImage: medPlusStickProduct,
    },
    {
      productName: "intensive moisturiser.",
      productLink: "/intensive-moisturiser",
      productImage: intensiveMoisturiserProduct,
    },
  ],
}

const MedPlus = () => (
  <Layout noHeaderInLayout={false} makeFooterSticky={false}>
    <SEO title="MedPlus Jar." />
    <SingleProduct data={product} inStock={true} outOfStockMsg={null} />
  </Layout>
)

export default MedPlus
